var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.cashData,"item-key":"id","sort-by":"name","search":_vm.buscar,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"loading":_vm.loading,"loading-text":"Cargando Movimientos de Caja...","no-data-text":"No hay registros para mostrar.","no-results-text":"No se encontraron registros en la búsqueda"},scopedSlots:_vm._u([{key:"item.entrada",fn:function(ref){
var item = ref.item;
return [(item.amount > 0)?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v(" "+_vm._s(item.amount)+" ")]):_vm._e()]}},{key:"item.salida",fn:function(ref){
var item = ref.item;
return [(item.amount < 0)?_c('v-chip',{attrs:{"small":"","color":"error"}},[_vm._v(" "+_vm._s(item.amount)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar Movimientos","outlined":"","dense":""},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((_vm.rol == 'Encargado' || _vm.rol == 'Administrador'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"elevation":"10","small":"","fab":"","color":"primary","dark":""},on:{"click":function($event){return _vm.$refs.dialogMovement.openDialog()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiCashPlus)+" ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Ingresar Movimiento de Caja")])])],1)]},proxy:true}])}),_c('dialogAddMovement',{ref:"dialogMovement",on:{"recargarListado":function($event){return _vm.getCashList()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }