<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="750"
  >
    <v-container fluid>
      <v-form ref="form">
        <v-card :loading="getLoading">
          <v-card-title>
            <v-row justify="center">
              <span class="text-h5 center">Movimientos de Caja</span>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row
              justify="center"
            >
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="operationTypeSelected"
                  :items="operationType"
                  item-value="id"
                  item-text="descripcion"
                  :append-icon="icons.mdiBallot "
                  label="Tipo de Movimiento"
                  dense
                  :rules="[Validaciones.requerido]"
                  outlined
                  hide-details="true"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="amount"
                  :append-icon="icons.mdiCurrencyUsd "
                  :rules="[Validaciones.requerido]"
                  label="Importe"
                  type="number"
                  dense
                  required
                  hide-details="true"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              justify="center"
            >
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="description"
                  :append-icon="icons.mdiCommentText"
                  :rules="[Validaciones.requerido]"
                  label="Detalle de la Operación"
                  dense
                  required
                  hide-details="true"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              depressed
              @click="closeDialog()"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              depressed
              :loading="isSaving"
              @click="ConditionInsert()"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-container>
  </v-dialog>
</template>
<script>
import axios from 'axios'

import {
  mdiCurrencyUsd, mdiBallot, mdiCommentText,
} from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiCurrencyUsd,
        mdiBallot,
        mdiCommentText,
      },
      isSaving: false,
      dialog: false,
      cashRegisterID: 0,
      amount: null,
      operationTypeSelected: null,
      description: null,
      getLoading: false,
      operationType: [{
        id: 3,
        descripcion: 'Ingresos Varios',
      },
      {
        id: 4,
        descripcion: 'Gastos Varios',
      },
      {
        id: 5,
        descripcion: 'Retiro Gerencia',
      },
      {
        id: 6,
        descripcion: 'Ingreso Gerencia',
      },
      {
        id: 7,
        descripcion: 'Pago a Empleado',
      },
      ],

      Validaciones: {
        requerido: v => !!v || 'Campo Requerido',

      },
    }
  },
  methods: {
    openDialog(client) {
      this.client = client
      this.dialog = true
    },
    ConditionInsert() {
      if (this.amount <= parseFloat(0)) {
        this.amount = null

        return
      }
      this.InsertMoviment()
    },
    closeDialog() {
      this.amount = null
      this.operationTypeSelected = null
      this.description = null
      this.dialog = false
    },
    async InsertMoviment() {
      if (this.$refs.form.validate()) {
        this.isSaving = true
        this.url = `${this.$store.getters.urlBase}/api/CashRegister/AddMovement`
        const params = {
          loggedPersonId: this.$store.getters.usuario.user_personid,
          cashRegisterID: this.$store.getters.caja.id,
          amount: this.amount,
          operationType: this.operationTypeSelected,
          description: this.description,

        }
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
        }
        await axios
          .post(this.url, params, config)
          .then(
            // eslint-disable-next-line no-return-assign
            response => {
              this.respuesta = response
              this.isSaving = false
              if (this.respuesta.status === 200) {
                this.$store.dispatch('showSnack', {
                  text: 'Movimiento de Caja Ingresado con Éxito',
                  color: 'success',
                  timeout: 2500,
                })
                this.$emit("recargarListado")
                this.closeDialog()
                this.$router.push({ name: 'cash' })
              }
            },
          )
          .catch(e => {
            if (e.response) {
              this.mensajeSnack = e.response.data
            } else {
              this.mensajeSnack = 'Error de conexion'
            }
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,
            })
            this.isSaving = false
          })
      }
    },
  },
}
</script>
