<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="cashData"
      item-key="id"
      sort-by="name"
      :search="buscar"
      :header-props="headerProps"
      :footer-props="footerProps"
      :loading="loading"
      loading-text="Cargando Movimientos de Caja..."
      no-data-text="No hay registros para mostrar."
      no-results-text="No se encontraron registros en la búsqueda"
    >
      <template v-slot:item.entrada="{ item }">
        <v-chip
          v-if="item.amount > 0"
          small
          color="success"
        >
          {{ item.amount }}
        </v-chip>
      </template>
      <!-- <template v-slot:item.date="{ item }">
        <span>{{ moment(item.date).format("dd/MM/yyyy HH:mm") }}</span>
      </template> -->
      <template v-slot:item.salida="{ item }">
        <v-chip
          v-if="item.amount < 0"
          small
          color="error"
        >
          {{ item.amount }}
        </v-chip>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="buscar"
            append-icon="mdi-magnify"
            label="Buscar Movimientos"
            outlined
            dense
          ></v-text-field>
          <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
          <v-spacer></v-spacer>

          <v-tooltip

            left
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="(rol == 'Encargado' || rol == 'Administrador')"
                elevation="10"
                small
                fab
                color="primary"
                dark
                class="mx-2"
                v-bind="attrs"
                v-on="on"
                @click="$refs.dialogMovement.openDialog()"
              >
                <v-icon>
                  {{ icons.mdiCashPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>Ingresar Movimiento de Caja</span>
          </v-tooltip>
        </v-toolbar>
      </template>
    </v-data-table>
    <dialogAddMovement
      ref="dialogMovement"
      @recargarListado="getCashList()"
    ></dialogAddMovement>
  </v-container>
</template>

<script>
import {
  mdiPencil, mdiDeleteEmpty, mdiAccountPlus, mdiEraser, mdiFaceWoman, mdiFaceMan, mdiAccountMultiplePlus, mdiAccountMinus, mdiCashPlus, mdiEyeOutline,
} from '@mdi/js'

import axios from 'axios'
import dialogAddMovement from '../AddMovement.vue'

export default {
  components: { dialogAddMovement },
  props: {
    rol: {
      type: String,
      required: true,
      default: 'Cobrador',
    },
  },
  data: () => ({
    dialogDelete: false,
    icons: {
      mdiPencil,
      mdiDeleteEmpty,
      mdiAccountPlus,
      mdiEraser,
      mdiFaceWoman,
      mdiFaceMan,
      mdiAccountMultiplePlus,
      mdiAccountMinus,
      mdiCashPlus,
      mdiEyeOutline,
    },
    headers: [
      { text: 'Fecha', value: 'date' },
      { text: 'Cliente', value: 'client' },
      { text: 'Detalle', value: 'description' },
      { text: 'Entrada', value: 'entrada' },
      { text: 'Salida', value: 'salida' },

    ],
    headerProps: {
      sortByText: 'Ordenar por',
    },
    footerProps: {
      itemsPerPageText: 'Registros por página',
      pageText: '{0}-{1} de {2}',
      itemsPerPageOptions: [5, 10, 20, 50, 100, { text: 'Todos', value: -1 }],
    },
    cashData: [],
    buscar: '',
    clienteAEliminar: null,
    loading: false,
    mensajeSnack: '',
    employeePersonId: null,
    dialogDesasignar: false,
    clienteADesasignar: null,
  }),

  // watch: {
  //   dialogDelete(val) {
  //     val || this.closeDelete()
  //   },
  // },
  mounted() {
    //    if (this.$route.params.id || this.rol === 'Cobrador') { this.getClientListOfEmployee() } else { this.getClientList() }
    this.getCashList()
  },
  methods: {

    async getCashList() {
      this.url = `${this.$store.getters.urlBase}/api/CashRegister/list`
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',

        },
        url: this.url,
      }
      this.loading = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.cashData = respuesta.data
        this.loading = false

      // this.getListarDiasMantenimiento();
      } else {
        this.loading = false

      // this.colorSnack = "red";
      // this.mensajeSnack = respuesta.data;
      // this.Showsnackbar = true;
      }
    },

  },
}
</script>
